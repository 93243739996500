
@import "../_variables.scss";

@font-face {
  font-family: 'chipmong-bold';
  src: url('../../fonts/Gotham-Black.woff2') format('woff2'),
       url('../../fonts/Gotham-Black.woff') format('woff'),
       url('../../fonts/Gotham-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
    font-family: 'chipmong';
      src: url('../../fonts/Gotham-Book.woff2') format('woff2'),
           url('../../fonts/Gotham-Book.woff') format('woff'),
           url('../../fonts/Gotham-Book.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
}
@font-face {
  font-family: 'chipmong-kh';
  src: url('../../fonts/Battambang-Regular.woff2') format('woff2'),
       url('../../fonts/Battambang-Regular.woff') format('woff'),
       url('../../fonts/Battambang-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'chipmong-kh-bold';
    src: url('../../fonts/Bayon.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}

h1,h2,h3,h4,h5,h6{
  font-family: chipmong-bold, chipmong-kh-bold;
}

html{
    scroll-behavior: smooth;
}
//Khmer language

body{
    font-family: 'chipmong-kh', chipmong;
     font-weight: bold;
     height: 100vh;
}

.container-fluid{
  padding: 0;
}
.container {
  max-width: 1920px;
  width: 85%;
}

.style-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.text-gray{
    color: $text-gray !important;
}
.text-primary{
    color: $main !important;
}
.one-line{
  -webkit-line-clamp: 1; /* number of lines to show */
}
.two-line{
  -webkit-line-clamp: 2; /* number of lines to show */
}
.three-line{
  -webkit-line-clamp: 3;
}
.five-line{
  -webkit-line-clamp: 5;
}
.six-line{
  -webkit-line-clamp: 6;
}
.one-line,.two-line,.three-line,.five-line,.six-line{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.blog {
  .title h4{
    font-size: 18px;
  }
  .date{
    font-size: 14px;
    font-family: 'chipmong-kh','chipmong';
  }
}
.title-page{
  padding: 10% 0;
  width: 70%;
  margin: 0 auto;
  h1.title{
    color: white;
  }
  p.sub-title{
    color: white;
  }
}
.bg-img{
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
}
/*------Padding-----*/
.st-4{
    padding-top: 4% ;
}
.mst-4{
    margin-top: 4%;
}
.sy-6{
  padding-top: 7% ;
  padding-bottom: 6% ;
}
.sy-5{
  padding-top: 4% ;
  padding-bottom: 5% ;
}
.sy-3{
  padding-top: 3% ;
  padding-bottom: 3% ;
}
.sb-6{
  padding-bottom: 6%;
}
p{
  margin-bottom: 0;
}
a{
  text-decoration: none!important;
  color: $main;
}
.dropdown-item:active{
  background-color: transparent;
}
.line-left::after{
  border-bottom: 5px solid #FFA100;
  width: 60px;
  display: block;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  text-align: left;
  padding: 5px;
}
.line-center::after{
  border-bottom: 5px solid #FFA100;
  width: 60px;
  display: block;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
  padding: 0.725rem;
}
/*------Title-----*/
h6.sub-title{
  font-size: 22px;
  color: $text-gray;
}
h1.title{
  font-size: 45px;
}
.title{
    color: $main ;
 text-transform: uppercase;
    &.title-border{
        padding-bottom: 10%;
        &::after{
            border-bottom: 5px solid #FFA100;
            width: 10%;
            display: block;
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            margin: auto;
            padding: 0.725rem;
        }
    }
}
/*--------Table----------*/
.table{
    color: $text-bold;
}
.table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 1px;
}
.view-more{
  a{
    font-family: "chipmong-kh-bold", "chipmong-bold", "sans-serif";
    font-size: 20px;
    span{
      -webkit-transition: 0.3s;
      -moz-transition: 0.3s;
      -ms-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
    }
    i{
      padding: 5px 0 0 10px;
      font-size: 18px;
    }
  }

}
.view-more a:hover{
  span{
    padding-right: 10px;
  }

}
.dropdown-toggle::after{
  display: none;
}
/*======Orange Line==*/
.line:after {
  width: 60px;
  height: 5px;
  content: '';
  background-color: $orange-main;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -20px;
}
  .button a.btn-orange {
    font-family: "chipmong-kh-bold", "chipmong-bold", "sans-serif";
    font-size: 24px;
    background-color: $orange-main;
    padding: 10px 30px;
    color: white;
    border-radius: 4px;
    display: inline-block;
  }
.orange{
  color: $orange-main;
}
.shadow-img{
  -webkit-box-shadow: 0px 6px 13px 1px rgba(0,3,102,0.2);
  -moz-box-shadow: 0px 6px 13px 1px rgba(0,3,102,0.2);
  box-shadow: 0px 6px 13px 1px rgba(0,3,102,0.2);
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  &:hover{
    -webkit-box-shadow: 0px 6px 13px 1px rgba(0,3,102,0.05);
    -moz-box-shadow: 0px 6px 13px 1px rgba(0,3,102,0.05);
    box-shadow: 0px 6px 13px 1px rgba(0,3,102,0.05);
  }
}
.center{
  display: flex;
  .content-center{
    display: flex;
    // align-items: center;
    vertical-align: middle;
    width: 100%;

  }
}
/*================================*/
.box-content{
  padding-left: 6%;

  h6.sub-title {
    font-weight: 700;
    color: $text-gray;
  }
  h1.title{
    color: $main;
    font-weight: 700;
    position: relative;
  }
  .room{
    .sub-title{
      color: $text-gray;
    }
    .value{
      font-family: "chipmong-kh-bold", "chipmong-bold", "sans-serif";
      span.cap {
        padding-right: 15px;
      }
    }
    .label{
      font-weight: 700;
    }
  }
}
/*========Gallery========*/
.gallery {
  position: relative;
  a.view{
    &:before{
      background-color: #0009;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: 0.3s;
      -moz-transition: 0.3s;
      -ms-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
    }
  }
  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5% 5%;
    opacity: 0;
    visibility: hidden;

    h4.title{
      bottom: 15px;
      font-size: 30px;
    }
    .text-body p{
      top: 15px;
    }
    h4.title , .text-body p{
      position: relative;
      color: white;
      -webkit-transition: 0.4s;
      -moz-transition: 0.4s;
      -ms-transition: 0.4s;
      -o-transition: 0.4s;
      transition: 0.4s;
    }
  }
  &:hover {
    a.view{
      &:before{
        opacity: 1;
        visibility: visible;
      }
    }
    .content {
      opacity: 1;
      visibility: visible;
      h4.title{
        bottom: 0;
      }
      .text-body p{
        top: 0;
      }
    }

  }

  .modal{
    top: 15%;
    padding-right: 0!important;
  }
  .modal-header .close {
    position: absolute;
    right: 25px;
    top: 23px;
    height: 40px;
    width: 40px;
    opacity: 1;
    color: #fff;
    padding: 10px;
    background-color: #003664;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-dialog.display {
    width: 60%;
    max-width: 1920px;
    .modal-content{
      overflow-y: scroll;
      &::-webkit-scrollbar-track
      {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
      }

      &::-webkit-scrollbar
      {
        width: 0;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb
      {
        background-color: transparent;
      }
    }
    .modal-body{

    }
  }
}
.breadcrumb-wrap .breadcrumb{
  padding: 0.75rem 0;
}
.gallery-img{
  .card {
    background: #fff;
    color: #444;
    border-radius: 2px;

    &-image {
      background: #ffffff;
      display: block;
      padding-top: 100%;
      position: relative;
      width: 100%;

      img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.btn-smooth a img{
  animation-name: scroll;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0;
    transform: translateY(26px)
  }
}
/*=================Loading=============*/
.loading-o{
  width: 100%;
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .lds-ellipsis {
    display: flex;
    position: relative;
    width: 80px;
    height: 80px;
    div {
      position: absolute;
      top: 33px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: $main;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
      &:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
      }
      &:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      &:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      &:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
      }
    }
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
}
/*=================Image-Control=============*/
.image-c{
  display: block;
  position: relative;
  width: 100%;
  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.list-point{
  .list-item {
    .item{
      color: $text-gray;
      font-size: 18px;
      font-weight: 700;
      padding-left: 10%;
      span.border{
        color: #FFF;
        border-radius: 50% !important;
        border-color: $orange-main !important;
        background: $orange-main;
        width: 18px;
        height: 18px;
        position: relative;
        line-height: 16px;
        text-align: center;
        display: inline-block;
        margin-right: 0.5rem;
        i{
          font-size:13px;
        }
      }
    }
  }
}
.breadcrumb-wrap .breadcrumb {
  .breadcrumb-item {
    font-size: 20px;
    font-weight: 700;
    font-family: 'chipmong-kh-bold', chipmong-bold , sans-serif;
    &.active span, a{
      font-size: 20px;
    }

  }
}
@media only screen and (max-width: 1024px) {
  .breadcrumb-wrap .breadcrumb{
    height: 60px;
    .breadcrumb-item {
      &.active span, a{
        font-size: 20px;
      }

    }
  }
  .container {
    max-width: 1920px;
    width: 95%;
  }
  h1.title{
    font-size: 40px;
  }
  .gallery {
    a.view{
      &:before{
        opacity: 1;
        visibility: visible;
      }
    }
    .content {
      padding: 5% 5%;
      opacity: 1;
      visibility:visible;

      h4.title{
        bottom: 0;
        font-size: 24px;
      }
      .text-body{
        &.six-line{
          -webkit-line-clamp: 3;
        }
        p{
          top: 0;
          font-size: 14px;
        }
      }
    }

    .modal-dialog.display {
      width: 85%;
    }
  }
    .blog {
        .title h4 {
            font-size: 16px;
        }
    }
}
@media only screen and (max-width: 768px) {
  // .blog .date {
  //   padding-top: 5px!important;
  //   padding-bottom: 5px!important;
  // }
  .title-page {
    h1.title{
font-size: 30px ;
      margin: 0;
    }
    .sub-title{
      font-size: 14px;
    }
  }
  .breadcrumb-wrap .breadcrumb{
    height: 50px;
    .breadcrumb-item {
      &.active span, a{
        font-size: 16px;
      }

    }
  }
  .title-page{
    width: 100%;
  }
  h1.title{
    font-size: 32px;
  }
  h6.sub-title{
    font-size: 16px;
  }
  .view-more a{
    font-size: 16px;
    i{
      font-size: 16px;
    }
  }
  .gallery {
    .modal-dialog.display{
      .modal-body{
        font-size: 14px;
      }
    }

  }
  .list-point .list-item .item{
    padding-left: 0;
    font-size: 16px;
  }
  .button a.btn-orange{
    font-size: 16px;
    padding: 5px 15px;
    border-radius: 2px;
  }
}
@media only screen and (max-width: 668px) {
  .gallery {
    .modal-header .close{
      height: 30px;
      width: 30px;
      font-size: 16px;
    }
    .modal{
      top: 10%;
      .modal-dialog.display {
        .modal-body {
          .col-6 {
            flex: 0 0 100%;
            max-width: 100%;
            .image{
              width: 60%;
              margin: 0 auto 15px;
            }
          }
        }
      }
    }

  }
  }
@media only screen and (max-width: 576px) {
  .title-page h1.title{
    font-size: 24px;
  }
  .gallery {

    .modal{

      .modal-dialog.display {
        margin: 0.5rem auto;
        .modal-header {
          padding: 10px !important;
          h4{
            margin: 0;
          }
        }
        .modal-body {

          .col-6 {
            .image{
              width: 75%;

            }
          }
        }
      }
    }

  }
}
@media only screen and (max-width: 480px) {
  .button a.btn-orange{
    font-size: 13px;
  }
}
@media only screen and (max-width: 376px) {
  .gallery .modal .modal-dialog.display .modal-body .col-6 .image{
    width: 90%;
  }

}
// 28 Apr 2023
